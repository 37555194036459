import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import Layout from './shared/Layout'; // New layout component
import Home from './pages/Home';
import './App.css';
import './assets/css/Global.css';
import '@fontsource/inter';
import Profile from './pages/Profile';
import Splash from './pages/Splash';
import Transactions from './pages/Transactions';
import Referral from './pages/Referral';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import Wallet from './pages/Wallet';
import Otp from './pages/Otp';
import TermsAndConditions from './pages/TermsAndConditions';
import Deposit from './pages/Deposit';
import Withdraw from './pages/Withdraw';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Success from './shared/Success';
import Transfer from './pages/Transfer';
import Cashout from './pages/CashOut';
import Pledge from './pages/Pledge';
import { jwtDecode } from 'jwt-decode'; // Import jwt-decode to decode the token
import { Snackbar, Button } from '@mui/material'; // Import Snackbar and Button for popups
import { useTranslation } from 'react-i18next'; // Import useTranslation for i18next
import AdminLogin from './pages/admin/AdminLogin';
import AdminUserManagement from './pages/admin/AdminUserMangement';
import AdminFooter from './pages/admin/shared/AdminFooter';
import AdminHeader from './pages/admin/shared/AdminHeader';
import AdminTransactionManagement from './pages/admin/AdminTransactionManagement';
import AdminPendingDeposits from './pages/admin/AdminPendingDeposits';
import AdminPendingWithdrawals from './pages/admin/AdminPendingWithdrawals';
import Invest from './pages/Invest';
import Group from './pages/Group';
import GroupList from './pages/GroupList';
import TransferRegisterPoints from './pages/TransferRegisterPoints';
import InvestRegisterPoints from './pages/InvestReigsterPoints';

function App() {
  return (
    <Router>
      <MainApp />
    </Router>
  );
}

function MainApp() {
  const { pathname } = useLocation(); // Get the current location
  const navigate = useNavigate(); // Hook to programmatically navigate
  const { i18n } = useTranslation(); // Get i18n instance for language handling
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state for token expiry message
  const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message

  const noHeaderFooterRoutes = ['/', '/login', '/signup', '/forgot-password', '/terms-and-conditions', '/verify-otp', '/admin', '/admin/login'];
  const adminHeaderFooterRoutes = ['/admin/user-management', '/admin/transaction-management', '/admin/pending-deposits', '/admin/pending-withdrawals'];
  const hideHeaderFooter = noHeaderFooterRoutes.includes(pathname);
  const adminHeaderFooter = adminHeaderFooterRoutes.includes(pathname);

  // Utility function to check token expiry
  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Current time in seconds
      return decodedToken.exp < currentTime; // Token is expired if current time is greater than expiration
    } catch (error) {
      console.error('Error decoding token:', error);
      return true; // If token can't be decoded, consider it expired
    }
  };

  // Function to handle user logout
  const logout = () => {
    localStorage.removeItem('token'); // Clear token
    setSnackbarMessage('Your session has expired. Please log in again.'); // Set message for Snackbar
    setSnackbarOpen(true); // Show the Snackbar
    navigate('/login'); // Redirect to login
  };

  // Check token expiry on load and at intervals
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && isTokenExpired(token)) {
      logout(); // Log the user out if token is expired
    }

    // Optionally, set an interval to check token expiry every minute
    const interval = setInterval(() => {
      if (token && isTokenExpired(token)) {
        logout(); // Log the user out if token expires during the session
      }
    }, 60000); // Check every 60 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [navigate]);

  // Fetch user language setting from backend
  const fetchUserLanguage = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || isTokenExpired(token)) return;

      const response = await fetch('/api/user', { // API endpoint to fetch user data
        headers: {
          'Authorization': `Bearer ${token}` // Include the token in the request
        }
      });

      if (!response.ok) throw new Error('Failed to fetch user language');

      const user = await response.json();

      if (user && user.language) {
        i18n.changeLanguage(user.language); // Set the language in i18next
      }
    } catch (error) {
      console.error('Failed to fetch user language:', error);
    }
  };

  // Fetch language when the component mounts
  useEffect(() => {
    fetchUserLanguage();
  }, []);

  // Snackbar close handler
  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close the Snackbar
  };

  return (
    <div className="App">
      {!hideHeaderFooter && !adminHeaderFooter && <Header />} {/* Conditionally render Header */}
      {!hideHeaderFooter && adminHeaderFooter && <AdminHeader />}

      <main style={{
        paddingTop: hideHeaderFooter ? '0px' : '70px',
        paddingBottom: hideHeaderFooter ? '0px' : '70px',
        transition: 'padding 0.3s ease-in-out',
      }}>
        <Routes>
          <Route path="/" element={<Splash />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/verify-otp" element={<Otp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/home" element={<Home />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/referral" element={<Referral />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/group" element={<Group />} />
          <Route path="/group-list" element={<GroupList />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/cashout" element={<Cashout />} />
          <Route path="/pledge" element={<Pledge />} />
          <Route path="/invest" element={<Invest />} />
          <Route path="/invest-register" element={<InvestRegisterPoints />} />
          <Route path="/transfer" element={<Transfer />} />
          <Route path="/transfer-register" element={<TransferRegisterPoints />} />
          <Route path="/success" element={<Success />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

          {/* Admin */}
          <Route path="/admin" element={<Navigate to="/admin/login" replace />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/user-management" element={<AdminUserManagement />} />
          <Route path="/admin/transaction-management" element={<AdminTransactionManagement />} />
          <Route path="/admin/pending-deposits" element={<AdminPendingDeposits />} />
          <Route path="/admin/pending-withdrawals" element={<AdminPendingWithdrawals />} />
        </Routes>
      </main>

      {!hideHeaderFooter && !adminHeaderFooter && <Footer />} {/* Conditionally render Footer */}
      {!hideHeaderFooter && adminHeaderFooter && <AdminFooter />}

      {/* Snackbar for token expiration notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <Button color="inherit" size="small" onClick={handleSnackbarClose}>
            OK
          </Button>
        }
      />
    </div>
  );
}

export default App;
