import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, TextField, Typography, Snackbar, Link, FormControl, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { ArrowBackIos } from '@mui/icons-material';
import loginBg from '../../assets/images/login/loginBg.jpg'; 
import logo from '../../assets/images/logo.png';
import { useTranslation } from 'react-i18next';

const OuterBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: `url(${loginBg})`, 
  backgroundSize: 'cover', 
  backgroundPosition: 'center', 
  backgroundRepeat: 'no-repeat', 
}));

const StyledBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  margin: 'auto',
  padding: '25px',
  width: '100wh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: '8px', 
}));

function AdminLogin() {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value); 
  };

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); 
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
        console.log(username, password)
      const response = await axios.post('/api/admin/auth/login', { username, password });

      if (response.status === 200) {
        localStorage.setItem('adminToken', response.data.token); 

        if (username.toLowerCase() == 'mmfinance') {
          navigate('/admin/pending-deposits'); 
        } else {
          navigate('/admin/user-management'); 
        }
        
      }
    } catch (error) {
      console.error('Login failed:', error.response?.data || error.message);
      setSnackbarMessage("Login failed. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <OuterBox>
      <StyledBox>
        <img src={logo} alt="Logo" style={{ height: '60px', objectFit: 'contain', marginBottom: 100 }} />
        <Typography variant="h6">Admin Login</Typography> {/* Use translation */}
        <form onSubmit={handleLogin}>
          <TextField
            label="Username"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <Button 
            sx={{ 
              background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
              color: 'white',
              '&:hover': {
                opacity: 0.9,
                background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
              },
            }} 
            variant="contained" 
            type="submit" 
            fullWidth>
            Login {/* Use translation */}
          </Button>
        </form>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
      </StyledBox>
    </OuterBox>
  );
}

export default AdminLogin;
