import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, TextField, Typography, Snackbar, Link, FormControl, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { ArrowBackIos } from '@mui/icons-material';
import loginBg from '../assets/images/login/loginBg.jpg'; 
import logo from '../assets/images/logo.png';
import logoCn from '../assets/images/logo_cn.png'; // Import Chinese logo
import { useTranslation } from 'react-i18next';

const OuterBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: `url(${loginBg})`, 
  backgroundSize: 'cover', 
  backgroundPosition: 'center', 
  backgroundRepeat: 'no-repeat', 
}));

const StyledBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  margin: 'auto',
  padding: '25px',
  width: '100wh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: '8px', 
}));

function Login() {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value); 
  };

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); 
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/login', { username, password });

      if (response.status === 200) {
        localStorage.setItem('token', response.data.token); 
        localStorage.setItem('language', response.data.language); 

        i18n.changeLanguage(response.data.language); // Set language in i18next

        navigate('/home'); 
      }
    } catch (error) {
      console.error('Login failed:', error.response?.data || error.message);

      if (error.response?.data.message === "User not verified") {
        navigate(`/verify-otp?email=${error.response?.data.email}`);
      } else {
        setSnackbarMessage(error.response?.data.message || t('login.loginFailed')); // Use translation
        setSnackbarOpen(true);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const logoToDisplay = i18n.language === 'chi' || i18n.language === 'zho' ? logoCn : logo;

  return (
    <OuterBox>
      <StyledBox>
        <Grid container justifyContent={"space-between"} alignItems='center' sx={{ marginBottom: 5 }}>
          <Grid xs={5}>
            <Button startIcon={<ArrowBackIos />} onClick={goBack} />
          </Grid>
          <Grid xs={5}>
            <FormControl>
              <Select
                label={t('language')}
                defaultValue="eng" 
                onChange={handleLanguageChange} 
                sx={{ color: 'primary.main' }}
              >
                <MenuItem value="eng">{t('languages.english')}</MenuItem>
                <MenuItem value="chi">{t('languages.chinese')}</MenuItem>
                <MenuItem value="ind">{t('languages.indonesian')}</MenuItem>
                <MenuItem value="kor">{t('languages.korean')}</MenuItem>
                <MenuItem value="hin">{t('languages.hindi')}</MenuItem>
                <MenuItem value="jpn">{t('languages.japanese')}</MenuItem>
                <MenuItem value="th">{t('languages.thai')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <img src={logoToDisplay} alt="Logo" style={{ height: '60px', objectFit: 'contain', marginBottom: 100 }} />
        <Typography variant="h6">{t('login.title')}</Typography> {/* Use translation */}
        <form onSubmit={handleLogin}>
          <TextField
            label={t('login.username')} 
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label={t('login.password')} 
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <Button 
            sx={{ 
              background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
              color: 'white',
              '&:hover': {
                opacity: 0.9,
                background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
              },
            }} 
            variant="contained" 
            type="submit" 
            fullWidth>
            {t('login.loginButton')} {/* Use translation */}
          </Button>
        </form>

        <Link sx={{marginTop: 2}} onClick={() => navigate('/forgot-password')} underline="always">
          {t('login.forgotPassword')} {/* Use translation */}
        </Link>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
      </StyledBox>
    </OuterBox>
  );
}

export default Login;
