import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/joy';
import { Paper, Fab } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import { AccountBalanceWallet, Logout as LogoutIcon, MonetizationOn } from '@mui/icons-material';
import RedeemIcon from '@mui/icons-material/Redeem';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Import jwt-decode to decode the token

function AdminFooter() {

    const location = useLocation();
    const navigate = useNavigate();
    const isActive = (path) => location.pathname === path;
    const [depositOnly, setDepositOnly] = useState(false);

    const handleLogout = () => {
        localStorage.removeItem('adminToken');
        navigate('/admin/login');
    };

    useEffect(() => {
        const token = localStorage.getItem('adminToken');
        const decodedToken = jwtDecode(token);
        const adminUserId = decodedToken.id;

        if (adminUserId == 2) {
            setDepositOnly(true);
        }
    });

    return (
        <Paper
            variant="outlined"
            square
            className="footer-nav"
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                padding: '10px 0',
                boxShadow: '0px -1px 5px rgba(0,0,0,0.1)',
                borderTop: '1px solid #e0e0e0',
                zIndex: 10,
            }}
        >
            {depositOnly ? (
                // Only show "Pending Deposits" button if depositOnly is true
                <>
                    <Box className="nav-item">
                        <IconButton onClick={() => navigate('/admin/pending-deposits')}>
                            <MonetizationOn fontSize="medium" sx={{ color: isActive('/admin/pending-deposits') ? '#3c1fc7' : 'inherit' }} />
                        </IconButton>
                        <p style={{ color: isActive('/admin/pending-deposits') ? '#3c1fc7' : 'inherit' }}>Pending Deposits</p>
                    </Box>

                    {/* Statistics Button */}
                    <Box className="nav-item">
                        <IconButton onClick={() => navigate('/admin/transaction-management')}>
                            <BarChartIcon fontSize="medium" sx={{ color: isActive('/admin/transaction-management') ? '#3c1fc7' : 'inherit' }} />
                        </IconButton>
                        <p style={{ color: isActive('/admin/transaction-management') ? '#3c1fc7' : 'inherit' }}>Transactions</p>
                    </Box>
                </>
                
            ) : (
                <>
                    {/* User Management */}
                    <Box className="nav-item">
                        <IconButton onClick={() => navigate('/admin/user-management')}>
                            <AccountCircleIcon fontSize="medium" sx={{ color: isActive('/admin/user-management') ? '#3c1fc7' : 'inherit' }} />
                        </IconButton>
                        <p style={{ color: isActive('/admin/user-management') ? '#3c1fc7' : 'inherit' }}>Users</p>
                    </Box>

                    {/* Statistics Button */}
                    <Box className="nav-item">
                        <IconButton onClick={() => navigate('/admin/transaction-management')}>
                            <BarChartIcon fontSize="medium" sx={{ color: isActive('/admin/transaction-management') ? '#3c1fc7' : 'inherit' }} />
                        </IconButton>
                        <p style={{ color: isActive('/admin/transaction-management') ? '#3c1fc7' : 'inherit' }}>Transactions</p>
                    </Box>

                    {/* Pending Deposits Button */}
                    <Box className="nav-item">
                        <IconButton onClick={() => navigate('/admin/pending-deposits')}>
                            <MonetizationOn fontSize="medium" sx={{ color: isActive('/admin/pending-deposits') ? '#3c1fc7' : 'inherit' }} />
                        </IconButton>
                        <p style={{ color: isActive('/admin/pending-deposits') ? '#3c1fc7' : 'inherit' }}>Pending Deposits</p>
                    </Box>

                    {/* Pending Withdrawals Button */}
                    <Box className="nav-item">
                        <IconButton onClick={() => navigate('/admin/pending-withdrawals')}>
                            <AccountBalanceWallet fontSize="medium" sx={{ color: isActive('/admin/pending-withdrawals') ? '#3c1fc7' : 'inherit' }} />
                        </IconButton>
                        <p style={{ color: isActive('/admin/pending-withdrawals') ? '#3c1fc7' : 'inherit' }}>Pending Withdrawals</p>
                    </Box>
                </>
            )}

            {/* Log Out Button */}
            <Box className="nav-item">
                <IconButton onClick={handleLogout}>
                    <LogoutIcon fontSize="medium" sx={{ color: '#e74c3c' }} />
                </IconButton>
                <p style={{ color: '#e74c3c' }}>Log Out</p>
            </Box>
        </Paper>
    );
}

export default AdminFooter;