import React, { useEffect, useState } from 'react';
import {
    Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    IconButton, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Checkbox,
    FormControlLabel, Pagination, InputAdornment
} from '@mui/material';
import { Edit, CheckCircle, Settings, Search, ControlPointDuplicate } from '@mui/icons-material';
import axios from 'axios';
import { Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AdminUserManagement = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(10); // Number of users per page
    const [searchTerm, setSearchTerm] = useState('');
    const [openActivationModal, setOpenActivationModal] = useState(false);
    const [activateAmount, setActivateAmount] = useState(0);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [openConfigModal, setOpenConfigModal] = useState(false);
    const [isLoan, setIsLoan] = useState(true); // Default to true
    const [openEditUserModal, setOpenEditUserModal] = useState(false);
    const [editedUser, setEditedUser] = useState({
        email: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        country: ''
    });
    const [openTopupModal, setOpenTopupModal] = useState(false);
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        if (!localStorage.getItem('adminToken')) {
            navigate("/admin/login");
        }

        // Fetch users data when component mounts
        fetchUsers();
    }, []);

    useEffect(() => {
        // Filter users based on search term
        setFilteredUsers(
            users.filter(user =>
                user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, users]);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('/api/admin/user/list', {
                headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` }
            });
            setUsers(response.data);
            setFilteredUsers(response.data); // Set filtered users initially
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset to first page when searching
    };

    // Pagination logic
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

    const handleOpenActivationModal = (userId) => {
        setSelectedUserId(userId);
        setOpenActivationModal(true);
    };

    const handleCloseActivationModal = () => {
        setOpenActivationModal(false);
        setActivateAmount(0);
    };

    const handleConfirmActivate = async () => {
        try {
            await axios.post(`/api/admin/user/activate`, { amount: activateAmount, user_id: selectedUserId }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` }
            });

            setSnackbarMessage(`Top Up successfully.`);
            setSnackbarOpen(true);
            fetchUsers();
            handleCloseActivationModal();
        } catch (error) {
            console.error('Error activating user:', error);
        }
    };

    const handleOpenEditUserModal = (user) => {
        setEditedUser(user);
        setOpenEditUserModal(true);
    };

    const handleCloseEditUserModal = () => {
        setOpenEditUserModal(false);
    };

    const handleSaveUserDetails = async () => {
        try {
            await axios.put(`/api/admin/user/update`, { user: editedUser }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` }
            });
            setSnackbarMessage(`Saved successfully.`);
            setSnackbarOpen(true);
            fetchUsers();
            handleCloseEditUserModal();
        } catch (error) {
            console.error('Error updating user details:', error);
            setSnackbarMessage(`Fail to update user details.`);
            setSnackbarOpen(true);
        }
    };

    const handleEditConfig = (userId, user) => {
        setSelectedUserId(userId);
        setSelectedUser(user);
        setOpenConfigModal(true);
    };

    const handleCloseConfigModal = () => {
        setOpenConfigModal(false);
    };

    const handleSaveConfig = async () => {
        try {
            await axios.post(`/api/admin/user/daily-roi/config`, { user_id: selectedUserId, range_from: selectedUser.range_from, range_to: selectedUser.range_to }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` }
            });

            setSnackbarMessage(`Saved successfully.`);
            setSnackbarOpen(true);
            handleCloseConfigModal();
            fetchUsers();
        } catch (error) {
            console.error('Error saving config:', error);
            setSnackbarMessage(`Fail to update user config.`);
            setSnackbarOpen(true);
        }
    };

    const handleRangeFromChange = (e) => {
        setSelectedUser((prevUser) => ({
            ...prevUser,
            range_from: e.target.value
        }));
    };

    const handleRangeToChange = (e) => {
        setSelectedUser((prevUser) => ({
            ...prevUser,
            range_to: e.target.value
        }));
    };

    const handleOpenTopupModal = (userId, user) => {
        setSelectedUserId(userId);
        setSelectedUser(user);
        setOpenTopupModal(true);
    };

    const handleCloseTopupModal = () => {
        setOpenTopupModal(false);
    };

    const handleTopup = async () => {
        try {
            await axios.post(`/api/admin/user/topup`, { user_id: selectedUserId, amount: selectedUser.topupAmount }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('adminToken')}` }
            });

            setSnackbarMessage(`Saved successfully.`);
            setSnackbarOpen(true);
            handleCloseTopupModal();
            fetchUsers();
        } catch (error) {
            console.error('Error topup:', error);
            setSnackbarMessage(`Fail to topup to user.`);
            setSnackbarOpen(true);
        }
    };

    const handleTopupAmountChange = (e) => {
        setSelectedUser((prevUser) => ({
            ...prevUser,
            topupAmount: e.target.value
        }));
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box sx={{ padding: '20px', margin: 'auto' }}>
            <Typography variant="h4" fontWeight="bold" mb={3} textAlign="center">
                User Management
            </Typography>

            {/* Search Bar */}
            <TextField
                variant="outlined"
                fullWidth
                placeholder="Search by username, email, or name"
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                }}
                sx={{ marginBottom: '20px' }}
            />

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="user management table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>ID</strong></TableCell>
                            <TableCell><strong>Username</strong></TableCell>
                            <TableCell><strong>Upline</strong></TableCell>
                            <TableCell><strong>Email</strong></TableCell>
                            <TableCell><strong>Full Name</strong></TableCell>
                            <TableCell><strong>Phone</strong></TableCell>
                            <TableCell><strong>Country</strong></TableCell>
                            <TableCell><strong>Verify Code</strong></TableCell>
                            <TableCell><strong>Verified</strong></TableCell>
                            <TableCell><strong>Activated</strong></TableCell>
                            <TableCell><strong>Remarks</strong></TableCell>
                            <TableCell align="center"><strong>Actions</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentUsers.length > 0 ? currentUsers.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell>{user.id}</TableCell>
                                <TableCell>{user.username}</TableCell>
                                <TableCell>{user.upline_name == 'agong1' ? '': user.upline_name}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.first_name} {user.last_name}</TableCell>
                                <TableCell>{user.phone_number}</TableCell>
                                <TableCell>{user.country}</TableCell>
                                <TableCell>{user.activate_code}</TableCell>
                                <TableCell>{user.is_verified === 1 ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{user.is_active === 1 ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{user.remarks}</TableCell>
                                <TableCell align="center">
                                    <IconButton
                                        color="success"
                                        onClick={() => handleOpenActivationModal(user.id)}
                                        title="Activate User"
                                    >
                                        <CheckCircle />
                                    </IconButton>
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleOpenEditUserModal(user)}
                                        title="Edit User"
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleEditConfig(user.id, user)}
                                        title="Edit Config"
                                    >
                                        <Settings />
                                    </IconButton>
                                    <IconButton
                                        color="warning"
                                        onClick={() => handleOpenTopupModal(user.id, user)}
                                        title="Top Up"
                                    >
                                        <ControlPointDuplicate />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )) : (
                            <TableRow>
                                <TableCell colSpan={12} align="center">
                                    No users found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Pagination */}
            <Box mt={2} display="flex" justifyContent="center">
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>

            {/* Activation Modal */}
            <Dialog open={openActivationModal} onClose={handleCloseActivationModal}>
                <DialogTitle>Enter Cash Wallet Amount to Invest</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Amount"
                        type="number"
                        fullWidth
                        value={activateAmount}
                        onChange={(e) => setActivateAmount(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseActivationModal} color="secondary">Cancel</Button>
                    <Button onClick={handleConfirmActivate} color="primary">Confirm</Button>
                </DialogActions>
            </Dialog>

            {/* Edit User Modal */}
            <Dialog open={openEditUserModal} onClose={handleCloseEditUserModal}>
                <DialogTitle>Edit User Details</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Email"
                        type="email"
                        fullWidth
                        value={editedUser.email}
                        onChange={(e) => setEditedUser({ ...editedUser, email: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="First Name"
                        type="text"
                        fullWidth
                        value={editedUser.first_name}
                        onChange={(e) => setEditedUser({ ...editedUser, first_name: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Last Name"
                        type="text"
                        fullWidth
                        value={editedUser.last_name}
                        onChange={(e) => setEditedUser({ ...editedUser, last_name: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Phone"
                        type="text"
                        fullWidth
                        value={editedUser.phone_number}
                        onChange={(e) => setEditedUser({ ...editedUser, phone_number: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Country"
                        type="text"
                        fullWidth
                        value={editedUser.country}
                        onChange={(e) => setEditedUser({ ...editedUser, country: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Remarks"
                        type="text"
                        fullWidth
                        value={editedUser.remarks}
                        onChange={(e) => setEditedUser({ ...editedUser, remarks: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditUserModal} color="secondary">Cancel</Button>
                    <Button onClick={handleSaveUserDetails} color="primary">Save</Button>
                </DialogActions>
            </Dialog>

            {/* Config Modal */}
            <Dialog open={openConfigModal} onClose={handleCloseConfigModal}>
                <DialogTitle>Edit User Daily ROI Range</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Range From"
                        type="number"
                        fullWidth
                        value={selectedUser?.range_from || ''}
                        onChange={handleRangeFromChange}
                    />
                    <TextField
                        margin="dense"
                        label="Range To"
                        type="number"
                        fullWidth
                        value={selectedUser?.range_to || ''}
                        onChange={handleRangeToChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfigModal} color="secondary">Cancel</Button>
                    <Button onClick={handleSaveConfig} color="primary">Save</Button>
                </DialogActions>
            </Dialog>

            {/* TopUp Modal */}
            <Dialog open={openTopupModal} onClose={handleCloseTopupModal}>
                <DialogTitle>Add Register Points</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Amount"
                        type="number"
                        fullWidth
                        value={selectedUser?.topupAmount || ''}
                        onChange={handleTopupAmountChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseTopupModal} color="secondary">Cancel</Button>
                    <Button onClick={handleTopup} color="primary">Save</Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </Box>
    );
};

export default AdminUserManagement;
