import React from 'react';
import { PuffLoader } from 'react-spinners';

function Loading() {
  return (
    <div style={styles.loaderContainer}>
      <PuffLoader color="#3c1fc7" size={60} />
    </div>
  );
}

const styles = {
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh', // Full page height
    width: '100vw',
    backgroundColor: '#f1f3f8', // Optional: semi-transparent background
  },
};

export default Loading;