import React, { useState, useEffect } from 'react';
import Loading from '../shared/Loading';
import { Snackbar } from '@mui/material';
import { Button, TextField, IconButton, Typography, Box } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import QRCode from 'react-qr-code'; // Fixed the import for QRCode
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

function Referral() {
    const { t } = useTranslation(); // Initialize the translation function
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');  
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate("/");
        }

        const fetchUser = axios.get('/api/user', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        Promise.all([fetchUser])
            .then(([userResponse]) => {
                // User
                setUser(userResponse.data);

                setTimeout(() => {
                    setIsLoading(false);
                }, 500);
            }).catch((error) => {
                console.error('Error fetching data:', error);
                navigate("/");
                setIsLoading(false);
            });
    }, []);

    const referralCode = user?.uuid;
    const referralLink = `${window.location.host}/signup?referral=${referralCode}`;

    // Function to copy the referral link to clipboard
    const copyLinkToClipboard = () => {
        navigator.clipboard.writeText(referralLink);
        setSnackbarMessage(t('referral.copied')); // Using translation key
        setSnackbarOpen(true);
    };

    const copyCodeToClipboard = () => {
        navigator.clipboard.writeText(referralCode);
        setSnackbarMessage(t('referral.copied')); // Using translation key
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    
    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="referral-page">
            <Box sx={{ padding: '20px', textAlign: 'center', maxWidth: '500px', margin: 'auto' }}>
                <h1>{t('referral.title')}</h1> {/* Using translation key for title */}
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    {t('referral.description')} {/* Using translation key for description */}
                </Typography>

                {/* QR Code */}
                <Box sx={{ margin: '20px auto' }}>
                    <QRCode value={referralLink} size={150} />
                </Box>

                {/* Referral Code with Copy Button */}
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
                    <TextField
                        variant="outlined"
                        value={referralCode} // Changed to use referralCode variable
                        InputProps={{
                            readOnly: true,
                        }}
                        sx={{ width: '70%', marginRight: 1 }}
                    />
                    <IconButton color="primary" onClick={copyCodeToClipboard}>
                        <ContentCopy />
                    </IconButton>
                </Box>

                {/* Instructions */}
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {t('referral.instructions')} {/* Using translation key for instructions */}
                </Typography>

                {/* Additional Button for Sharing */}
                <Button 
                    variant="contained" 
                    onClick={copyLinkToClipboard} 
                    sx={{ 
                        marginTop: '15px',
                        background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                        color: 'white',
                        '&:hover': {
                            background: 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)',
                            opacity: 0.9 
                        }
                    }}
                >
                    {t('referral.copy_link')} {/* Using translation key for button text */}
                </Button>
            </Box>

            {/* Snackbar for error messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </div>
    );
}

export default Referral;
