import React, { useState, useEffect } from 'react';
import { Typography, Button, Card, Radio, RadioGroup, FormControl, FormLabel, Input, Box } from '@mui/joy';
import { Snackbar } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loading from '../shared/Loading';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

function Pledge() {
  const { t } = useTranslation(); // Initialize the translation function
  const [isLoading, setIsLoading] = useState(true);
  const [amount, setAmount] = useState(null);
  const [duration, setDuration] = useState(7);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [user, setUser] = useState(null);
  const [interestWallet, setInterestWallet] = useState(null);
  const navigate = useNavigate();
  const bonusPercentage = ['0.5%', '0.6%', '0.7%', '0.8%', '1.0%'];

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate("/");
    }

    const fetchUser = axios.get('/api/user', {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    });

    const fetchUserWallets = axios.get('/api/user/wallets', {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    });

    Promise.all([fetchUser, fetchUserWallets])
      .then(([userResponse, walletsResponse]) => {
        setUser(userResponse.data);
        walletsResponse.data.forEach(wallet => {
          if (wallet['wallet_type'] === "Interest Wallet") {
            setInterestWallet(wallet);
          }
        });

        setIsLoading(false);
      }).catch((error) => {
        console.error('Error fetching data:', error);
        navigate("/");
        setIsLoading(false);
      });
  }, [navigate]);

  const handlePledge = async () => {
    if (amount > 0 && amount <= interestWallet['balance']) {
      setError(null);
      setIsLoading(true);

      const requestBody = {
        amount,
        duration
      };

      try {
        const response = await axios.post('/api/user/pledge', requestBody, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        if (response.status === 200) {
          navigate('/success', { state: { message: t('pledge.success') } }); // Using translation key
        } else {
          setSnackbarMessage(t('pledge.request_failed')); // Using translation key
          setSnackbarOpen(true);
        }
        setIsLoading(false);
      } catch (error) {
        setSnackbarMessage(error.response?.data.message || t('pledge.request_failed')); // Using translation key
        setSnackbarOpen(true);
        setIsLoading(false);
      }
    } else {
      setError(t('pledge.invalid_amount')); // Using translation key
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="withdraw-page">
      <Card variant="outlined" className="withdraw-card">

        {/* Back Button */}
        <Box display="flex" justifyContent="start" mt={3} ml={2}>
          <Button
            variant="solid"
            onClick={() => navigate(-1)} // Go back to the previous page
            sx={{
              background: 'linear-gradient(135deg, #FDE1A1 0%, #8A7146 50%, #57472C 100%)',
              color: '#ffffff',
              '&:hover': {
                background: 'linear-gradient(135deg, #FDE1A1 0%, #8A7146 50%, #57472C 100%)',
                opacity: 0.9,
              },
            }}
          >
            {t('group.back')}
          </Button>
        </Box>
        
        <Typography level="h4" className="withdraw-title">{t('pledge.title')}</Typography> {/* Using translation key */}
        <Typography level="body1" className="withdraw-description">
          {t('pledge.enter_amount')} {/* Using translation key */}
        </Typography>
        <Typography level="body1" className="withdraw-description">
          {t('pledge.gain_interest')} {/* Using translation key */}
        </Typography>
        
        {/* Amount Input */}
        <FormControl sx={{ mt: 2 }}>
          <FormLabel>{t('pledge.amount_label')}</FormLabel> {/* Using translation key */}
          <Input
            value={amount}
            onChange={(e) => {
              let inputAmount = e.target.value;

              // Enforce two decimal places
              if (inputAmount.includes('.')) {
                const [integerPart, decimalPart] = inputAmount.split('.');
                if (decimalPart.length > 2) {
                  inputAmount = `${integerPart}.${decimalPart.slice(0, 2)}`;
                }
              }

              const amountFloat = parseFloat(inputAmount) || 0;
              if (amountFloat >= 0 && amountFloat <= interestWallet['balance']) {
                setAmount(parseFloat(inputAmount));
              }
            }}
            type="number"
            placeholder={t('pledge.amount_placeholder')} // Using translation key
            max={interestWallet['balance']}
          />
          {error && <Typography color="danger" sx={{ mt: 1 }}>{error}</Typography>}
        </FormControl>

        <Typography style={{ textAlign: 'left', fontSize: '0.8rem', color: 'grey' }}>
          {t('pledge.withdraw_limit', { limit: interestWallet['balance'] })} {/* Using translation key with dynamic value */}
        </Typography>

        {/* Duration Input */}
        <FormControl sx={{ my: 2 }}>
          <FormLabel>{t('pledge.duration_label')}</FormLabel> {/* Using translation key */}
          <RadioGroup
            row
            name="duration"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          >
            {[7, 30, 60, 100, 150].map((day, i) => (
              <Box component="label" display="flex" alignItems="center" key={day} sx={{ cursor: 'pointer', mr: 3 }}>
                <Radio value={day.toString()} />
                <Typography sx={{ ml: 1 }}>{day} {t('pledge.days')} [{bonusPercentage[i]}]</Typography> {/* Using translation key */}
              </Box>
            ))}
          </RadioGroup>
        </FormControl>

        {/* Confirm Button */}
        <Button 
          variant="solid" 
          color="primary" 
          onClick={handlePledge} 
          className="withdraw-button"
          disabled={amount > interestWallet['balance']}
        >
          {t('pledge.confirm')} {/* Using translation key */}
        </Button>
      </Card>

      {/* Snackbar for error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
}

export default Pledge;
