import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/joy';
import axios from 'axios';
import { Paper, Fab } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import RedeemIcon from '@mui/icons-material/Redeem';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CasinoIcon from '@mui/icons-material/Casino';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { Snackbar } from '@mui/material';

function Footer() {
    const { t } = useTranslation(); // Initialize translation
    const location = useLocation();
    const navigate = useNavigate();
    const isActive = (path) => location.pathname === path;
    const [enrolledDailyRoi, setEnrolledDailyRoi] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {

        if (!localStorage.getItem('token')) {
            navigate("/");
        }

        // Define all API requests
        const fetchUserDailyRoi = axios.get('/api/user/wallets/daily-roi', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
    
        // Execute all requests concurrently
        Promise.all([fetchUserDailyRoi])
          .then(([dailyRoiResponse]) => {

            if (dailyRoiResponse.data.length > 0) {
                setEnrolledDailyRoi(true);
            }
            
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
            navigate("/");
          });
    }, []);

    const handleEnrollDaiyRoi = async () => {

        try {
            const response = await axios.post('/api/user/wallets/daily-roi', {},
              {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
              }
            );
      
            if (response.status === 200) {
                setTimeout(() => {
                    navigate('/success', { state: { message: t('modal.enrollSuccess', { interest_rate: response.data.rate.toFixed(2) }) } });
                    window.location.reload();
                }, 300)
            } else {
              setSnackbarMessage(t('modal.requestFailed'));
              setSnackbarOpen(true);
            }
        
        } catch (error) {
            setSnackbarMessage(error.response?.data.message || t('modal.requestFailed'));
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Paper
            variant="outlined"
            square
            className="footer-nav"
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                padding: '10px 0',
                boxShadow: '0px -1px 5px rgba(0,0,0,0.1)',
                borderTop: '1px solid #e0e0e0',
                zIndex: 10,
            }}
        >
            {/* Home Button */}
            <Box className="nav-item">
                <IconButton onClick={() => navigate('/home')}>
                    <HomeIcon fontSize="medium" sx={{ color: isActive('/home') ? '#3c1fc7' : 'inherit' }} />
                </IconButton>
                <p style={{ color: isActive('/home') ? '#3c1fc7' : 'inherit' }}>{t('footer.home')}</p>
            </Box>

            {/* Statistics Button */}
            <Box className="nav-item">
                <IconButton onClick={() => navigate('/transactions')}>
                    <BarChartIcon fontSize="medium" sx={{ color: isActive('/transactions') ? '#3c1fc7' : 'inherit' }} />
                </IconButton>
                <p style={{ color: isActive('/transactions') ? '#3c1fc7' : 'inherit' }}>{t('footer.transactions')}</p>
            </Box>

            {/* Floating Action Button */}
            <Fab
                onClick={handleEnrollDaiyRoi}
                color="primary"
                aria-label="add"
                className="fab-button"
                disabled={enrolledDailyRoi} // Conditionally disable the button
                sx={{
                    background: enrolledDailyRoi
                        ? '#B0B0B0' // Solid grey when disabled, no transparency
                        : 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)', // Gradient when enabled
                    color: 'white',
                    position: 'absolute',
                    top: '-30px', // Floating above the navbar
                    boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
                    '&:hover': {
                        background: enrolledDailyRoi
                            ? '#B0B0B0' // Keep grey on hover when disabled
                            : 'linear-gradient(135deg, #57bdff 0%, #6528cf 100%)', // Keep gradient when enabled
                        opacity: 1, // Ensure no transparency on hover
                    },
                    '&.Mui-disabled': {
                        backgroundColor: '#B0B0B0', // Enforce grey color
                        color: '#FFFFFF', // Keep white color when disabled
                        boxShadow: '0px 4px 10px rgba(0,0,0,0.2)', // Keep box-shadow even if disabled
                    },
                }}
            >
                <CasinoIcon fontSize="large" />
            </Fab>

            {/* Rewards Button */}
            <Box className="nav-item">
                <IconButton onClick={() => navigate('/referral')}>
                    <RedeemIcon fontSize="medium" sx={{ color: isActive('/referral') ? '#3c1fc7' : 'inherit' }} />
                </IconButton>
                <p style={{ color: isActive('/referral') ? '#3c1fc7' : 'inherit' }}>{t('footer.referral')}</p>
            </Box>

            {/* Wallet Button */}
            <Box className="nav-item">
                <IconButton onClick={() => navigate('/profile')}>
                    <AccountCircleIcon fontSize="medium" sx={{ color: isActive('/profile') ? '#3c1fc7' : 'inherit' }} />
                </IconButton>
                <p style={{ color: isActive('/profile') ? '#3c1fc7' : 'inherit' }}>{t('footer.profile')}</p>
            </Box>

            {/* Snackbar for error messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </Paper>
    );
}

export default Footer;
