import React, { useState, useEffect } from 'react';
import { Typography, Button, Card, FormControl, FormLabel, Input, Box } from '@mui/joy';
import { Snackbar } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loading from '../shared/Loading';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

function Cashout() {
    const { t } = useTranslation(); // Initialize the translation function
    const [isLoading, setIsLoading] = useState(true);
    const [amount, setAmount] = useState(null);
    const [walletAddress, setWalletAddress] = useState('');
    const [error, setError] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [user, setUser] = useState(null);
    const [cashWallet, setCashWallet] = useState(null);
    const [interestWallet, setInterestWallet] = useState(null);
    const [investWallet, setInvestWallet] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate("/");
        }

        const fetchUser = axios.get('/api/user', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        const fetchUserWallets = axios.get('/api/user/wallets', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        Promise.all([fetchUser, fetchUserWallets])
            .then(([userResponse, walletsResponse]) => {
                // User
                setUser(userResponse.data);

                // Wallets
                walletsResponse.data.forEach(wallet => {
                    if (wallet['wallet_type'] === "Cash Wallet") {
                        setCashWallet(wallet);
                    } else if (wallet['wallet_type'] === "Interest Wallet") {
                        setInterestWallet(wallet);
                    } else if (wallet['wallet_type'] === "Invest Wallet") {
                        setInvestWallet(wallet);
                    }
                });

                setTimeout(() => {
                    setIsLoading(false);
                }, 500);

            }).catch((error) => {
                console.error('Error fetching data:', error);
                navigate("/");
                setIsLoading(false);
            });
    }, []);

    const handleAmountChange = (e) => {
        const value = parseFloat(e.target.value);
        console.log(value);
        if (isNaN(value) || value <= 0) {
            setError(t('cashout.error.invalid_amount')); // Using translation key for error message
        } else if (value > parseFloat(interestWallet['balance'])) {
            setError(t('cashout.error.exceed_balance', { balance: interestWallet['balance'] })); // Using translation key for error message
        } else {
            setError(null); // Clear error if within valid range
        }

        setAmount(value);
    };

    const handleWithdraw = async () => {
        if (amount > 0 && amount <= interestWallet['balance']) {
            setError(null);
            setIsLoading(true);

            const requestBody = {
                amount
            };

            try {
                const response = await axios.post('/api/user/cash-out', 
                    requestBody,
                    {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                    }
                );

                if (response.status === 200) {
                    navigate('/success', { state: { message: t('cashout.success') } }); // Using translation key for success message
                } else {
                    setSnackbarMessage(t('cashout.error.request_failed')); // Using translation key for error message
                    setSnackbarOpen(true);
                }

                setIsLoading(false);

            } catch (error) {
                setSnackbarMessage(error.response?.data.message || t('cashout.error.request_failed')); // Using translation key for error message
                setSnackbarOpen(true);
                setIsLoading(false);
            }
        } else {
            setError(t('cashout.error.invalid_amount')); // Using translation key for error message
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="withdraw-page">
            <Card variant="outlined" className="withdraw-card">

                {/* Back Button */}
                <Box display="flex" justifyContent="start" mt={3} ml={2}>
                    <Button
                        variant="solid"
                        onClick={() => navigate(-1)} // Go back to the previous page
                        sx={{
                        background: 'linear-gradient(135deg, #FDE1A1 0%, #8A7146 50%, #57472C 100%)',
                        color: '#ffffff',
                        '&:hover': {
                            background: 'linear-gradient(135deg, #FDE1A1 0%, #8A7146 50%, #57472C 100%)',
                            opacity: 0.9,
                        },
                        }}
                    >
                        {t('group.back')}
                    </Button>
                </Box>

                <Typography level="h4" className="withdraw-title">{t('cashout.title')}</Typography> {/* Using translation key for title */}
                <Typography level="body1" className="withdraw-description">
                    {t('cashout.description.enter_amount')}
                </Typography>
                <Typography level="body1" className="withdraw-description">
                    {t('cashout.description.cash_wallet')}
                </Typography>
                <Typography level="body1" className="withdraw-description">
                    {t('cashout.description.invest_wallet')}
                </Typography>

                {/* Amount Input */}
                <FormControl sx={{ mt: 2 }}>
                    <FormLabel>{t('cashout.label.amount')}</FormLabel>
                    <Input
                        value={amount}
                        onChange={handleAmountChange}
                        type="number"
                        placeholder={t('cashout.placeholder.enter_amount')} // Using translation key for placeholder
                        max={interestWallet['balance']}
                    />
                    {error && <Typography color="danger" sx={{ mt: 1 }}>{error}</Typography>}
                </FormControl>

                <Typography style={{ textAlign: 'left', fontSize: '0.8rem', color: 'grey' }}>
                    {t('cashout.description.withdraw_limit', { balance: interestWallet['balance'] })} {/* Using translation key for withdraw limit */}
                </Typography>

                {/* Confirm Button */}
                <Button 
                    variant="solid" 
                    color="primary" 
                    onClick={handleWithdraw} 
                    className="withdraw-button"
                    disabled={amount > interestWallet['balance']}
                >
                    {t('cashout.button.confirm')} {/* Using translation key for button text */}
                </Button>
            </Card>

            {/* Snackbar for error messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </div>
    );
}

export default Cashout;
