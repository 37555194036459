import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import {
    Container,
    Box,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemText, 
    FormControl, 
    Select, 
    MenuItem
} from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import logo from '../assets/images/logo.png';
import logoCn from '../assets/images/logo_cn.png'; // Import Chinese logo
import { useTranslation } from 'react-i18next';

const TermsAndConditions = () => {
    const { t, i18n } = useTranslation();

    const handleLanguageChange = (event) => {
        i18n.changeLanguage(event.target.value); 
    };

    const navigate = useNavigate(); 
    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = () => {
        setIsScrolled(window.scrollY > 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const goBack = () => {
        navigate(-1); 
    };

    const logoToDisplay = i18n.language === 'chi' || i18n.language === 'zho' ? logoCn : logo;

    return (
        <Container>
            <Box
                className={`app-header ${isScrolled ? 'scrolled' : ''}`}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 16px',
                    height: '60px',
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <IconButton onClick={goBack}>
                    <ArrowBackIos />
                </IconButton>
                <img src={logoToDisplay} alt="Logo" style={{ height: '40px' }} />
                <FormControl>
                    <Select
                        label={t('language')}
                        defaultValue="eng" 
                        onChange={handleLanguageChange} 
                        sx={{ color: 'primary.main' }}
                    >
                        <MenuItem value="eng">{t('languages.english')}</MenuItem>
                        <MenuItem value="chi">{t('languages.chinese')}</MenuItem>
                        <MenuItem value="ind">{t('languages.indonesian')}</MenuItem>
                        <MenuItem value="kor">{t('languages.korean')}</MenuItem>
                        <MenuItem value="hin">{t('languages.hindi')}</MenuItem>
                        <MenuItem value="jpn">{t('languages.japanese')}</MenuItem>
                        <MenuItem value="th">{t('languages.thai')}</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Typography variant="h4" align="center" marginY={2}>
                {t('termsAndConditions.title')}
            </Typography>

            <List>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.welcome')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.generalOverview')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.acceptanceOfTerms')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.eligibility')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.internationalUse')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.userAccounts')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.accountCreation')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.accountTermination')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.inGameFeatures')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.virtualAssets')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.purchases')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.license')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.modificationOfAssets')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.investmentFeatures')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.investmentDescription')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.inGameInvestments')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.virtualAssetsOnly')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.returnsOnInvestments')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.riskOfLoss')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.realMoneyInvestments')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.investmentOpportunities')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.riskWarning')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.userResponsibility')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.noGuarantee')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.paymentsAndTransactions')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.inAppPurchases')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.refundPolicy')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.currencyConversionAndFees')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.intellectualProperty')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.ownership')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.userContent')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.prohibitedConduct')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.prohibitedActivities.1')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.prohibitedActivities.2')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.prohibitedActivities.3')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.prohibitedActivities.4')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.disclaimersAndLimitation')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.noWarranty')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.limitationOfLiability')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.investmentRisks')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.privacyAndDataProtection')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.personalData')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.internationalDataTransfers')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.disputeResolution')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.governingLaw')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.arbitration')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.localLegalCompliance')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.changesToTerms')} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={t('termsAndConditions.modification')} />
                </ListItem>
            </List>
        </Container>
    );
};

export default TermsAndConditions;
