import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // Pass the i18n instance to react-i18next
  .init({
    fallbackLng: 'eng', // Default language
    lng: 'eng', // Set the initial language
    debug: true, // Set to false in production

    interpolation: {
      escapeValue: false, // React already safeguards against XSS
    },
    resources: {
      eng: {
        translation: require('./locales/eng.json'),
      },
      chi: {
        translation: require('./locales/chi.json'),
      },
      kor: {
        translation: require('./locales/kor.json'),
      },
      hin: {
        translation: require('./locales/hin.json'),
      },
      ind: {
        translation: require('./locales/ind.json'),
      },
      jpn: {
        translation: require('./locales/jpn.json'),
      },
      th: {
        translation: require('./locales/th.json'),
      },
    },
  });

export default i18n;
