import React, { useState, useEffect } from 'react';
import { Avatar, Box, Typography, CircularProgress, useMediaQuery, Button } from '@mui/material';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tree, TreeNode } from 'react-organizational-chart';

// Dynamic styles for the nodes
const useStyles = (isMobile) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: isMobile ? '8px' : '10px', // Smaller padding on mobile
  borderRadius: '8px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
  textAlign: 'center',
  margin: isMobile ? '5px' : '10px', // Smaller margin on mobile
  cursor: 'pointer',
});

// Container styling for the chart
const containerStyles = (isMobile) => ({
  display: 'flex',
  justifyContent: 'center',
  overflowX: 'auto',
  padding: '10px',
  overflowY: 'auto',
  whiteSpace: 'nowrap',
  flexDirection: isMobile ? 'column' : 'row', // Vertical stacking on mobile
  margin: '0 auto', // Center the container
});

const GroupList = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [group, setGroup] = useState(null);
  const [downlines, setDownlines] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const downline = queryParams.get('downline');

  // Detect if the device is mobile
  const isMobile = useMediaQuery('(max-width: 600px)');
  const nodeStyles = useStyles(isMobile);
  const containerStyle = containerStyles(isMobile);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate("/");
    }

    const fetchUserGroups = async () => {
      try {
        let userGroupUrl = '/api/user/groups';
        if (downline) {
          userGroupUrl += `?downline=${downline}`;
        }
        const groupResponse = await axios.get(userGroupUrl, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        setGroup(groupResponse.data);
        setDownlines(groupResponse.data.invitees);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching group data:', error);
        navigate("/");
        setIsLoading(false);
      }
    };

    fetchUserGroups();
  }, [downline, navigate]);

  // Recursive function to render the hierarchy nodes
  const renderNode = (node) => (
    <TreeNode
      key={node.uuid} // Add a unique key to prevent key warning
      label={
        <Box style={nodeStyles}>
          <Avatar sx={{ mb: 1 }}>{node.first_name?.charAt(0)}</Avatar>
          <Typography variant="subtitle1">{`${node.first_name || ''} ${node.last_name || ''}`.trim()}</Typography>
          <Typography variant="body2">{t('group.totalInvest', { amount: node.total_deposit })}</Typography>
          <Typography variant="body2">
            {t('group.totalGroupInvest', { amount: node.total_group_invest })}
          </Typography>
          <Typography variant="body2">
            {t('group.isActive', { status: node.is_active ? t('group.active') : t('group.inactive') })}
          </Typography>
        </Box>
      }
    >
      {/* Only render child nodes if there are invitees */}
      {node.invitees && node.invitees.length > 0 && (
        <>
          {node.invitees.map((invitee) => renderNode(invitee))}
        </>
      )}
    </TreeNode>
  );

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {/* Back Button */}
      <Box display="flex" justifyContent="flex-start" mt={3} ml={2}>
        <Button
          variant="contained"
          onClick={() => navigate(-1)} // Go back to the previous page
          sx={{
            background: 'linear-gradient(135deg, #FDE1A1 0%, #8A7146 50%, #57472C 100%)',
            color: '#ffffff',
            '&:hover': {
              background: 'linear-gradient(135deg, #FDE1A1 0%, #8A7146 50%, #57472C 100%)',
              opacity: 0.9,
            },
          }}
        >
          {t('group.back')}
        </Button>
      </Box>

      {/* Organizational Chart */}
      <Box style={containerStyle}>
        {group && (
          <Tree
            lineWidth={downlines.length > 0 ? (isMobile ? '1px' : '2px') : '0px'} // Set to 0px when no downlines
            lineColor={'#ccc'}
            lineBorderRadius={'10px'}
            label={
              <Box style={nodeStyles}>
                {/* Render content with or without downlines */}
                <Avatar sx={{ mb: 1 }}>{group.first_name?.charAt(0)}</Avatar>
                <Typography variant="subtitle1">
                  {`${group.first_name || ''} ${group.last_name || ''}`.trim()}
                </Typography>
                <Typography variant="body2">
                  {t('group.totalInvest', { amount: group.total_deposit })}
                </Typography>
                <Typography variant="body2">
                  {t('group.totalGroupInvest', { amount: group.total_group_invest })}
                </Typography>
                <Typography variant="body2">
                  {t('group.isActive', { status: group.is_active ? t('group.active') : t('group.inactive') })}
                </Typography>
              </Box>
            }
          >
            {/* Render children only if there are downlines */}
            {downlines.length > 0 && downlines.map((downline) => renderNode(downline))}
          </Tree>
        )}
      </Box>
    </Box>
  );
};

export default GroupList;