// src/pages/Home.js
import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import moment from 'moment-timezone';
import axios from 'axios';
import { Modal, ModalDialog, Button, Typography } from '@mui/joy';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import SavingsIcon from '@mui/icons-material/Savings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import PaymentsIcon from '@mui/icons-material/Payments';
import BalanceIcon from '@mui/icons-material/Balance';
import CasinoIcon from '@mui/icons-material/Casino';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import { AccessTime, TrendingUp, Home as HomeIcon } from '@mui/icons-material';
import Loading from '../shared/Loading';
import { useNavigate } from 'react-router-dom';
import { Box, Card, LinearProgress } from '@mui/material';
import { Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { jwtDecode } from 'jwt-decode'; // Import jwt-decode to decode the token

function Home() {
    const { t } = useTranslation(); // Initialize translation

    const getGreeting = () => {
        const currentHour = new Date().getHours();
        if (currentHour < 12) {
            return t('greeting.morning');
        } else if (currentHour < 18) {
            return t('greeting.afternoon');
        } else {
            return t('greeting.evening');
        }
    };

    const greeting = getGreeting();
    const todayDate = moment().format('MMM YYYY');

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [cashWallet, setCashWallet] = useState(null);
    const [interestWallet, setInterestWallet] = useState(null);
    const [investWallet, setInvestWallet] = useState(null);
    const [registerWallet, setRegisterWallet] = useState(null);
    const [transactionList, setTransactionList] = useState([]);
    const [enrolledDailyRoi, setEnrolledDailyRoi] = useState(false);
    const [canInvest, setCanInvest] = useState(true);
    const [investData, setInvestData] = useState(null);
    const [progress, setProgress] = useState(0);
    const [showActivationModal, setShowActivationModal] = useState(false);
    const [pledgeList, setPledgeList] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isMasterPassword, setIsMasterPassword] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {

        if (!localStorage.getItem('token')) {
            navigate("/");
        }

        const token = localStorage.getItem('token');
        const decodedToken = jwtDecode(token);
        setIsMasterPassword(decodedToken.masterPassword);

        // Define all API requests
        const fetchUser = axios.get('/api/user', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
    
        const fetchUserWallets = axios.get('/api/user/wallets', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        const fetchUserTransactions = axios.get('/api/transaction/list', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            params: {
                transaction_type: 'Interest'
            }
        });

        const fetchPledgeList = axios.get('/api/user/pledge/list', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        const fetchUserInvest = axios.get('/api/user/invest', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
    
        // Execute all requests concurrently
        Promise.all([fetchUser, fetchUserWallets, fetchUserTransactions, fetchUserInvest, fetchPledgeList])
          .then(([userResponse, walletsResponse, transactionResponse, fetchUserInvestResponse , pledgeResponse]) => {
            // User
            setUser(userResponse.data);
            if (userResponse.data.is_active === 0) {
                setShowActivationModal(true);
            }

            // Transactions
            var transactionData = transactionResponse.data.data;
            setTransactionList(transactionData);

            // Get today's date in GMT+8
            const today = moment().tz('Asia/Singapore').format('YYYY-MM-DD');
            // Filter and sum up amounts by wallet_type for today only
            const walletSums = transactionData
                .filter(transaction => moment(transaction.transaction_date).tz('Asia/Singapore').format('YYYY-MM-DD') === today)
                .reduce((acc, transaction) => {
                    const walletType = transaction.wallet_type;
                    const amount = parseFloat(transaction.amount);

                    if (!acc[walletType]) {
                        acc[walletType] = 0;
                    }

                    // Add amount and ensure 2 decimal precision
                    acc[walletType] = parseFloat((acc[walletType] + amount).toFixed(2));
                    return acc;
                }, { 'Cash Wallet': 0.00, 'Interest Wallet': 0.00, 'Invest Wallet': 0.00, 'Register Wallet': 0.00 });
            
            // Wallets
            walletsResponse.data.map(wallet => {
                if (wallet['wallet_type'] === "Cash Wallet") {
                    wallet['profitToday'] = walletSums['Cash Wallet'];
                    setCashWallet(wallet);
                } else if (wallet['wallet_type'] === "Interest Wallet") {
                    wallet['profitToday'] = walletSums['Interest Wallet'];
                    setInterestWallet(wallet);
                } else if (wallet['wallet_type'] === "Invest Wallet") {
                    wallet['profitToday'] = walletSums['Invest Wallet'];
                    setInvestWallet(wallet);
                } else if (wallet['wallet_type'] === "Register Wallet") {
                    wallet['profitToday'] = walletSums['Register Wallet'];
                    setRegisterWallet(wallet);
                }
            })

            // Pledge
            setPledgeList(pledgeResponse.data);

            // Invest
            //setCanInvest(fetchUserInvestResponse.data.invest);
            setInvestData(fetchUserInvestResponse.data.data);

            if (fetchUserInvestResponse.data.data) {
                const totalInvested = parseFloat(fetchUserInvestResponse.data.data.invest_amount);
                const totalEarnings = parseFloat(fetchUserInvestResponse.data.data.earn_amount);
                const maxEarnings = parseFloat(fetchUserInvestResponse.data.data.max_amount);

                setProgress(((totalInvested +  totalEarnings) / maxEarnings) * 100)
            }

            setTimeout(() => {
                setIsLoading(false);
            }, 500)
            
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
            navigate("/");
            setError(error.message);
            setIsLoading(false);
          });
      }, []);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleNavigation = () => {
        navigate('/wallet'); // Change the route to wherever you want to navigate
    };

      
    if (isLoading) {
        return <Loading />;
    }

    return (
        <div>
            <div className="user-greeting">
                <Avatar src={require(`../assets/images/avatar/${user.profile_picture_id}.png`)} style={{marginRight: "15px"}}/>
                <div className="greeting-text">
                    <h1 className="greeting-title">{t('home.greeting', { name: user.first_name + ' ' + user.last_name })}</h1>
                    <p className="greeting-subtitle">{greeting}</p>
                </div>
            </div>

            <div className='wallet-section'>
            <div className="wallet-card wallet-card-default">
                <div className="card-content">
                    <div className="card-header">
                        <h4>{t('wallet.cash')}</h4>
                        <AccountBalanceWalletIcon className='validity' fontSize="small" />
                    </div>

                    <p className="profit-text">{t('wallet.profitToday', { amount: cashWallet['profitToday'].toFixed(2) })} { t('wallet.currency') }</p>
                    <h2 className='balance-text'>{t('wallet.balance', { amount: cashWallet['balance'] })} { t('wallet.currency') }</h2>
                    
                    <div className="card-buttons-2">
                        {/* Deposit Button */}
                        <button 
                            className="card-button" 
                            onClick={() => navigate('/deposit')} 
                            disabled={!canInvest} 
                            style={{
                                color: !canInvest ? 'grey' : 'inherit', 
                                cursor: !canInvest ? 'not-allowed' : 'pointer'
                            }}
                        >
                            <AttachMoneyIcon fontSize="small" />
                            <span>{t('wallet.deposit')}</span>
                        </button>

                        {/* Withdraw Button */}
                        <button 
                            className="card-button" 
                            onClick={() => navigate('/withdraw')}
                            disabled={isMasterPassword} 
                            style={{
                                color: isMasterPassword ? 'grey' : 'inherit', 
                                cursor: isMasterPassword ? 'not-allowed' : 'pointer'
                            }}
                        >
                            <PaymentsIcon fontSize="small" />
                            <span>{t('wallet.withdraw')}</span>
                        </button>

                        {/* Transfer Button */}
                        <button 
                            className="card-button" 
                            onClick={() => navigate('/transfer')}
                        >
                            <SwapHorizIcon fontSize="small" />
                            <span>{t('wallet.transfer')}</span>
                        </button>

                        {/* Invest Button */}
                        <button 
                            className="card-button" 
                            onClick={() => navigate('/invest')}
                            disabled={!canInvest}
                            style={{
                                color: !canInvest ? 'grey' : 'inherit', 
                                cursor: !canInvest ? 'not-allowed' : 'pointer'
                            }}
                        >
                            <TrendingUp fontSize="small" style={{ color: !canInvest ? 'grey' : 'inherit' }} />
                            <span>{t('home.invest')}</span>
                        </button>
                    </div>
                </div>
            </div>
                <div className="wallet-card wallet-card-variation1">
                    <div className="card-content">
                        <div className="card-header">
                            <h4>{t('wallet.interest')}</h4>
                            <AllInclusiveIcon className='validity' fontSize="small" />
                        </div>
                        
                        <p className="profit-text">{t('wallet.profitToday', { amount: interestWallet['profitToday'].toFixed(2) })} { t('wallet.currency') }</p>
                        <h2 className='balance-text'>{t('wallet.balance', { amount: interestWallet['balance'] })} { t('wallet.currency') }</h2>
                        
                        <div className="card-buttons-2">
                            {/* Withdraw Button */}
                            <button className="card-button" onClick={() => navigate('/cashout')}>
                                <PaymentsIcon fontSize="small" />
                                <span>{t('wallet.cashOut')}</span>
                            </button>

                            {/* Pledge Button */}
                            <button className="card-button" onClick={() => navigate('/pledge')}>
                                <BalanceIcon fontSize="small" />
                                <span>{t('wallet.pledge')}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="wallet-card wallet-card-variation2">
                    <div className="card-content">
                        <div className="card-header">
                            <h4>{t('wallet.invest')}</h4>
                            <SavingsIcon className='validity' fontSize="small" />
                        </div>

                        <p className="profit-text">{t('wallet.profitToday', { amount: investWallet['profitToday'].toFixed(2) })} { t('wallet.currency') }</p>
                        <h2 className='balance-text'>{t('wallet.balance', { amount: investWallet['balance'] })} { t('wallet.currency') }</h2>
                        
                        <div className="card-buttons-2">
                            {/* Invest Button */}
                            <button 
                                className="card-button" 
                                onClick={() => navigate('/invest')}
                                disabled={!canInvest}
                                style={{
                                    color: !canInvest ? 'grey' : 'inherit', 
                                    cursor: !canInvest ? 'not-allowed' : 'pointer'
                                }}
                            >
                                <TrendingUp fontSize="small" style={{ color: !canInvest ? 'grey' : 'inherit' }} />
                                <span>{t('home.invest')}</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="wallet-card wallet-card-variation3">
                    <div className="card-content">
                        <div className="card-header">
                            <h4>{t('wallet.register')}</h4>
                            <AccessibilityIcon className='validity' fontSize="small" />
                        </div>

                        <p className="profit-text">{t('wallet.profitToday', { amount: registerWallet['profitToday'].toFixed(2) })} { t('wallet.currency') }</p>
                        <h2 className='balance-text'>{t('wallet.balance', { amount: registerWallet['balance'] })} { t('wallet.currency') }</h2>
                        
                        <div className="card-buttons-2">
                            {/* Invest Button */}
                            <button 
                                className="card-button" 
                                onClick={() => navigate('/invest-register')}
                                disabled={!canInvest}
                                style={{
                                    color: !canInvest ? 'grey' : 'inherit', 
                                    cursor: !canInvest ? 'not-allowed' : 'pointer'
                                }}
                            >
                                <TrendingUp fontSize="small" style={{ color: !canInvest ? 'grey' : 'inherit' }} />
                                <span>{t('home.invest')}</span>
                            </button>

                            {/* Transfer Button */}
                            <button 
                                className="card-button" 
                                onClick={() => navigate('/transfer-register')}
                            >
                                <SwapHorizIcon fontSize="small" />
                                <span>{t('wallet.transfer')}</span>
                            </button>

                        </div>
                    </div>
                </div>
            </div>

            <div className="wallet-view-all">
                <div className="wallet-view-all-header">
                    <a onClick={() => navigate('/wallet')} className="view-all">{t('home.viewAllWallet')}</a>
                </div>
            </div>

            <div className="transaction-list">
                <div className="transaction-header">
                    <h3>{t('home.currentInvest')}</h3>
                </div>

                {!investData ? (
                        <Typography sx={{ textAlign: 'center', color: '#666', fontSize: '16px', mt: 2 }}>
                            {t('transaction.empty')}
                        </Typography>
                    ) : (
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column', // Stack items vertically
                                padding: '16px',
                                borderRadius: '16px',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                backgroundColor: '#fff',
                                mt: 2
                            }}
                            >
                            {/* Top Section with Icon and Info */}
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                {/* Left Side with Icon and Loan Info */}
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box
                                    sx={{
                                    backgroundColor: '#ffe5e7',
                                    borderRadius: '50%',
                                    padding: '10px',
                                    marginRight: '12px',
                                    }}
                                >
                                    <TrendingUp sx={{ color: '#ff5a5f', fontSize: '30px' }} />
                                </Box>
                                <Box>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#3ac79b' }}>
                                    +${investData.earn_amount}
                                    </Typography>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
                                    ${(parseFloat(investData.invest_amount) + parseFloat(investData.earn_amount)).toFixed(2)} ({progress.toFixed(0)}%)
                                    </Typography>
                                </Box>
                                </Box>

                                {/* Right Side with Next EMI Date */}
                                <Box sx={{ textAlign: 'right' }}>
                                <Typography variant="body2" sx={{ color: '#888' }}>
                                    {t('home.earningCap')}
                                </Typography>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#333' }}>
                                    ${investData.max_amount}
                                </Typography>
                                </Box>
                            </Box>

                            {/* Progress Bar */}
                            <Box sx={{ width: '100%', marginTop: '12px' }}>
                                <LinearProgress
                                variant="determinate"
                                value={progress}
                                sx={{
                                    height: '8px',
                                    borderRadius: '4px',
                                    backgroundColor: '#fce4e4',
                                    '& .MuiLinearProgress-bar': {
                                    backgroundColor: '#ff5a5f',
                                    },
                                }}
                                />
                            </Box>
                            </Card>
                )}

                
            </div>

            <div className="transaction-list">
                <div className="transaction-header">
                    <h3>{t('home.activePledges')}</h3>
                </div>

                <Box sx={{ padding: '16px', margin: 'auto', borderRadius: '12px' }}>
                    {pledgeList.length === 0 ? (
                        <Typography sx={{ textAlign: 'center', color: '#666', fontSize: '16px' }}>
                            {t('transaction.empty')}
                        </Typography>
                    ) : (
                        pledgeList.map((pledge) => (
                            <Card 
                                key={pledge.id} 
                                sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    padding: '16px', 
                                    marginBottom: '16px', 
                                    borderRadius: '12px', 
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' 
                                }}
                            >
                                <Box sx={{ mr: 2 }}>
                                    <AccessTime style={{ color: '#5a67d8', fontSize: '40px' }} />
                                </Box>
                                <Box>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                                        {t('home.balance')}: ${pledge.current_amount}
                                    </Typography>
                                    <Typography sx={{ fontSize: '14px', color: '#666', fontWeight: 'bold' }}>
                                        {t('home.duration')}: {pledge.duration_days} {t('pledge.days')}
                                    </Typography>
                                    <Typography sx={{ fontSize: '12px', color: '#888', mt: '4px' }}>
                                        {t('home.endOn')}: {moment(pledge.end_date).format('DD/MM/YYYY HH:mm')}
                                    </Typography>
                                </Box>
                            </Card>
                        ))
                    )}
                </Box>
                
            </div>

            <div className="transaction-list">
                <div className="transaction-header">
                    <h3>{t('home.monthlyEarnings')}</h3>
                    <a onClick={() => navigate('/transactions')} className="view-all">{t('home.viewAll')}</a>
                </div>
                <p className="transaction-date">{todayDate}</p>
                
                {/* Conditional rendering based on the length of the transactions array */}
                {transactionList.length === 0 ? (
                    <p className="no-transactions" style={{textAlign: 'center'}}>{t('home.noTransactions')}</p>
                ) : (
                    transactionList.map((transaction, index) => (
                    <div className="transaction-item" key={index}>
                        <div className="transaction-details">
                            <h4 className="transaction-title">
                                {/* Handle "P2P Transfer to" and "P2P Receive from" cases */}
                                {transaction.description.startsWith('P2P Transfer to') ? (
                                    <>
                                        {t('transactionsDesc.P2P Transfer to')} {transaction.description.replace('P2P Transfer to ', '')}
                                    </>
                                ) : transaction.description.startsWith('P2P Receive from') ? (
                                    <>
                                        {t('transactionsDesc.P2P Receive from')} {transaction.description.replace('P2P Receive from ', '')}
                                    </>
                                ) : (
                                    // Default case for other descriptions
                                    t(`transactionsDesc.${transaction.description}`)
                                )}
                            </h4>
                            <p className="transaction-category">{ moment(transaction.transaction_date).format('DD/MM/YYYY HH:mm:ss')}</p>
                        </div>
                        <div className="transaction-amount">
                            <p className="amount">{transaction.transaction_type === 'Deposit' || transaction.transaction_type === 'Interest' || transaction.transaction_type === 'Receive' ? '+' : '-'}{transaction.amount} { t('wallet.currency') }</p>
                        {transaction.wallet_type == "Cash Wallet" &&
                            <p className="payment-method">{t('transactions.cashWallet')}</p>
                        }
                        {transaction.wallet_type == "Invest Wallet" &&
                            <p className="payment-method">{t('transactions.interestWallet')}</p>
                        }
                        {transaction.wallet_type == "Interest Wallet" &&
                            <p className="payment-method">{t('transactions.investWallet')}</p>
                        }
                        {transaction.wallet_type == "Register Wallet" &&
                            <p className="payment-method">{t('transactions.registerWallet')}</p>
                        }
                        </div>
                    </div>
                    ))
                )}
            </div>
            
            {/* User not active */}
            <Modal open={showActivationModal}>
                <ModalDialog>
                <Typography level="h4">{t('home.accountNotActive')}</Typography>
                <Typography>{t('home.accountNotActiveSubtitle')}</Typography>
                <Button 
                    onClick={() => navigate('/deposit')} 
                    sx={{ mt: 2, backgroundColor:'#3c63e2'}}
                >
                    {t('home.activeNow')}
                </Button>
                </ModalDialog>
            </Modal>

            {/* Snackbar for error messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        
        </div>
    );
}

export default Home;